const AboutMe = () => {
  return (
    <section>
      <h2 className="title-font pink-text h2-tag">About Me</h2>
      <p className="white-text p-tag">
        Hello! I'm Pedro Modesto, a passionate and versatile Software Engineer
        based in Jersey City, New Jersey. My journey into the realm of software
        development began with a strong foundation in military service, where I
        developed key attributes such as discipline, leadership, and
        adaptability.
      </p>
    </section>
  );
};
export default AboutMe;
