import urlShortener from "../assets/images/portfolio/urlShortener.png";
import Edgy from "../assets/images/portfolio/Edgy.jpeg";
import RestCountries from "../assets/images/portfolio/RestCountries.jpeg";
import Bankist from "../assets/images/portfolio/Bankist.jpeg";

const projectsData = [
  {
    id: "rest-countries",
    img: RestCountries,
    name: "Rest Countires",
    stack: ["< Node.js />", "< PostgresSQL />", "< React.js />"],
    src: "https://github.com/pedrovily1/rei-clone-react/tree/main#readme",
    source: "https://github.com/pedrovily1/rei-clone-react",
    description:
      "OEI Shop is a vibrant e-commerce platform showcasing premium outdoor products. Crafted by a diverse five-member team skilled in React, PostgreSQL, and Web Design. The site stands as a testament to swift collaboration and the fusion of technical acumen with aesthetic mastery, offering a seamless user experience for outdoor enthusiasts.",
  },
  {
    id: "bankist",
    img: Bankist,
    name: "Bankist",
    stack: ["< React.JS />", "< Tailwind />", "< HTML5 />"],
    src: "https://github.com/pedrovily1/VIDIA-RTX-40-product-detail#readme",
    source: "https://github.com/pedrovily1/VIDIA-RTX-40-product-detail",
    description:
      "Led a team to develop the Vidia RTX Product Page, enhancing UI/UX with a responsive design, interactive 'Architecture' section, and dynamic image comparison tool, using React, NodeJS, PostgreSQL, and Tailwind CSS. The project highlights our commitment to combining aesthetic design with functionality.",
  },
  {
    id: "edgy",
    img: Edgy,
    name: "Edgy",
    stack: [
      "< PostgresSQL />",
      "< React.js />",
      "< Express.js />",
      "< Bcrypt />",
    ],
    src: "https://github.com/pedrovily1/learnlaunchnexus#readme",
    source: "https://github.com/pedrovily1/learnlaunchnexus",
    description:
      "Collaborated with a team to develop the Learn Launch Nexus Dashboard, a full-stack application enhancing interactions between students and career services at educational institutes. Crafted a robust API server, developed 15+ interactive React components for improved UX/UI, and implemented secure authentication for 500+ users.",
  },
];

export { projectsData };
