import ProjectsPreview from "./ProjectsPreview";

const PortfolioPreview = () => {
  return (
    <article className="portfolio">
      <section className="portfolio-text">
        <h2 className="title-font pink-text h2-tag">Portfolio</h2>
      </section>
      <ProjectsPreview />
    </article>
  );
};

export default PortfolioPreview;
