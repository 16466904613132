const Footer = () => {
  return (
    <article className="footer">
      <a href="https://github.com/pedrovily1/" target="_blank" rel="noreferrer">
        Designed & Built by Pedro Modesto &copy;2023
      </a>
    </article>
  );
};

export default Footer;
