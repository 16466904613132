import { useEffect } from "react";
import { Footer } from "../../components";

const Contact = () => {
  useEffect(() => {
    document.title = "Contact | Pedro Modesto";
  });
  return (
    <article className="contact-main">
      <h2 className="title-font pink-text h2-tag">Contact Me</h2>
      <p className="white-text p-tag">
        For contact please email me at{" "}
        <link href="mailto:pedrovily67@gmail.com">pedrovily67@gmail.com</link>
      </p>
      <Footer />
    </article>
  );
};

export default Contact;
