const ContactPreview = () => {
  return (
    <article className="contact">
      <h2 className="title-font pink-text h2-tag">Contact Me</h2>
      <p className="white-text p-tag">
        Feel free to email me at pedrovily67@gmail.com
      </p>
    </article>
  );
};

export default ContactPreview;
