import Projects from "./Projects";
import Footer from "../../components/footer/Footer";
import ScrollButton from "../../components/ScrollButton";
import { useEffect } from "react";

const Portfolio = () => {
  useEffect(() => {
    document.title = "Portfolio | Pedro Modesto";
  });
  return (
    <article className="portfolio-main">
      <Projects />
      <ScrollButton />
      <Footer />
    </article>
  );
};

export default Portfolio;
